import React from 'react';
import AddToCalendar from '../../utils/AddToCalendar/AddToCalendar';
import ParkImg from '../../assets/img/park.jpg';
import styles from './Address.module.scss'

const Address = () => {
  const styleProp = (index: number) => {
    return { "--i": index } as React.CSSProperties
  }

  return (
    <section className={styles['address-section']} data-aos="fade-right">
      <div className="flip-letter title cherry">
        <span style={styleProp(1)}>D</span>
        <span style={styleProp(2)}>ó</span>
        <span style={styleProp(3)}>n</span>
        <span style={styleProp(4)}>d</span>
        <span style={styleProp(5)}>e</span>
        <span style={styleProp(6)}>&nbsp;</span>
        <span style={styleProp(7)}>y</span>
        <span style={styleProp(8)}>&nbsp;</span>
        <span style={styleProp(9)}>C</span>
        <span style={styleProp(10)}>u</span>
        <span style={styleProp(11)}>á</span>
        <span style={styleProp(12)}>n</span>
        <span style={styleProp(13)}>d</span>
        <span style={styleProp(14)}>o</span>
      </div>

      <div className={styles['info']}>
        <div className="mt-3 card" id="address" data-aos="zoom-in-down">
          <h3 className="monster">
            <span className="light-purple">F</span>
            <span className="green">I</span>
            <span className="orange">E</span>
            <span className="light-blue">S</span>
            <span className="yellow">T</span>
            <span className="pink">A</span>
          </h3>
          <img className={styles['img-border']} height="260" src={ParkImg} alt="Park" />
          <h5>Nombre del lugar</h5>
          <p className="mb-0"><u>Cuándo:</u> [Día], [00] de [mes]] del [año].</p>
          <p className="mb-0"><u>Horario:</u> De [00:00] a [00:00] hrs.</p>
          <p className="mb-0"><u>Dirección</u>: Calle, número, Alcaldía.</p>

          <a href="https://www.google.com.mx/maps/preview" target="_blank" rel="noreferrer" className="border-0 mt-3 w-100">
            <button className="btn-blue fill w-100 mb-2">VER MAPA</button>
          </a>

          <AddToCalendar />
        </div>

        <div className="mt-3 card balloon-sully" id="info" data-aos="zoom-in-down">
          <h3 className="monster">
            <span className="light-purple">I</span>
            <span className="green">N</span>
            <span className="orange">D</span>
            <span className="light-blue">I</span>
            <span className="yellow">C</span>
            <span className="pink">A</span>
            <span className="light-purple">C</span>
            <span className="green">I</span>
            <span className="orange">O</span>
            <span className="light-blue">N</span>
            <span className="yellow">E</span>
            <span className="pink">S</span>
          </h3>
          <p className="mb-0">Qui nunc <u>loqueris?</u> Ecce qui cogitatis? 🚶🏻‍♀️, Vos scitis quanta ego <u>faciam</u> annum Id est, ut ego dixi vobis non <u>credunt</u> 🎈 Scis quid si ne subito placuit ire in  <u>opus</u>?</p>
          <p className="mb-0">Negotium <u>sufficere</u> posse 🚊, recensentur in NASDAQ vadit ventre sursum. <u>Perit!</u>, Non est amplius extra me existant. <u>Non</u> manifeste tu scis nesciunt qui loquebantur.</p>
          <p className="mb-0">Sic fiat mihi fila  <u>vobis ego</u> 🚘, sum nolite ingredi in periculo <u>Skyler</u> Sum in periculo!</p>
          <p className="mb-0">Ut <u>sagittis</u> metus aperit ostium, <u>et arbitraris</u> me et habet?</p>
          <p className="mb-0">Non ego sum ille qui pulsat! <u>Locutus est tibi?</u> Respondeo dicendum esset iustus? ☀️, Quæ? Quem populum? Mensis abhinc Gus occidere vellet uterque 🚴🏼, Et nunc, utatur 🛶, LAB et trahit vos de 🐴 quae 🥤, a socio gunman? 🥒, A lenta guy? 🍿 Numquid aliquo tibi 😆 Et dicit quod videt te 🌮 Qualis est is lascivio venatus.</p>
          <p className="mb-0">Putat quod surdus es? Non potest vere putes quod oblito ⚽️. nedum Gale, nedum Victoris ... quod pertinet ad omne nefas <u>et qui</u> 👶🏻, Suus satis. <u>Quod etiam optime</u>  Vos ite post eum <u>fistulae</u>.</p>
        </div>
      </div>
  </section>
  );
};

export default Address;