import React from 'react';
import BooPresent from '../../assets/img/boo_present.png';
import MikeCamping from '../../assets/img/mike_camping.png';
import styles from './Dresscode.module.scss';

const Dresscode = () => {
  const styleProp = (index: number) => {
    return { "--i": index } as React.CSSProperties
  }

  return (
    <section id="dress-code" className={styles['dress-code-section']} data-aos="fade-up">
        <div className="flip-letter title cherry">
          <span style={styleProp(1)}>D</span>
          <span style={styleProp(2)}>r</span>
          <span style={styleProp(3)}>e</span>
          <span style={styleProp(4)}>s</span>
          <span style={styleProp(5)}>s</span>
          <span style={styleProp(6)}>&nbsp;</span>
          <span style={styleProp(7)}>C</span>
          <span style={styleProp(8)}>o</span>
          <span style={styleProp(9)}>d</span>
          <span style={styleProp(10)}>e</span>
        </div>
        <div>
          <img className="img-circle m-1" data-aos="zoom-out" src={MikeCamping} alt="Dress Code Mike" />
          <img className="img-circle m-1" data-aos="zoom-out" src={BooPresent} alt="Dress Code Boo" />
        </div>
        <p className="mt-2 mb-0">Ropa cómoda o su outfit más monstruoso</p>
      </section>
  );
};

export default Dresscode;
