import React from 'react';
import AmazonImg from '../../assets/img/Amazon.png';
import PresentImg from '../../assets/img/present_party.png';
import styles from './Presents.module.scss';

const Present = () => {
  const styleProp = (index: number) => {
    return { "--i": index } as React.CSSProperties
  }

  return (
    <section id="presents" className={styles['presents-section']} data-aos="zoom-in">
      <div className="flip-letter title cherry mb-3">
        <span style={styleProp(1)}>R</span>
        <span style={styleProp(2)}>e</span>
        <span style={styleProp(3)}>g</span>
        <span style={styleProp(4)}>a</span>
        <span style={styleProp(5)}>l</span>
        <span style={styleProp(6)}>o</span>
        <span style={styleProp(7)}>s</span>
      </div>
      <p>Para nosotros tu presencia es nuestro mejor regalo, pero si deseas hacernos llegar un obsequio, te sugerimos las siguientes opciones:</p>

      <div className={styles['info-container']}>
        <a data-aos="fade-down"target="_blank" role="button" rel="noreferrer" href="https://www.amazon.com.mx">
          <div className="card btns">
            <img src={AmazonImg} alt="Amazon" />
            <p className="mb-0">Amazon</p>
          </div>
        </a>
        <div data-aos="fade-down">
          <div className="card btns">
            <img src={PresentImg} alt="Present" />
            <div>
              <p className="mb-0">Obsequio, Sobre o Tarjeta de Regalo</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Present;
