import React from 'react';
import styles from './Footer.module.scss';


const Footer = ({intro}: any) => (
  <footer className={`${styles['footer-wrapper']} ${intro ? styles['fixed'] : ''}`}>
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="mb-0">Copyright © {(new Date().getFullYear())} | Sitio realizado por<b><a href="https://teinvitaasu.party/" target="_blank" rel="noopener noreferrer"> Invitaciones Inteligentes</a></b></p>
        </div>
        <div>
          <a href="https://www.instagram.com/teinvitaasu.party" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
