import React, { useState } from 'react';
import IntroImg from '../../assets/cover/intro.png'
import styles from './Intro.module.scss'

const Init = ({ handleIntro}: any) => {
  const [show, setShow] = useState(true);

  const toggleButton = () => {
    setShow(false);
    handleIntro(show)
  }

  return (
    <section className={styles['intro-section']}>
      <img className="animate-img" src={IntroImg} alt="Intro" />
      <button className="btn-purple pulse mt-3" onClick={toggleButton} 
        data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">ABRIR INVITACION</button>
    </section>
  );
};

export default Init;
