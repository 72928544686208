import React from 'react';
import BooPartyImg from '../../assets/img/boo_party.png'
import MikePartyImg from '../../assets/img/mike_party.png'
import SullyPartyImg from '../../assets/img/sully_party.png'
import WhatsAppIcon from '../../assets/img/whatsapp.png'
import styles from './Confirm.module.scss'

const Name = () => {
  const styleProp = (index: number) => {
    return { "--i": index } as React.CSSProperties
  }

  return (
    <section id="confirm" className={styles['confirm-section']} data-aos="fade-down">
      <div className="flip-letter title cherry">
        <span style={styleProp(1)}>C</span>
        <span style={styleProp(2)}>o</span>
        <span style={styleProp(3)}>n</span>
        <span style={styleProp(4)}>f</span>
        <span style={styleProp(5)}>i</span>
        <span style={styleProp(6)}>r</span>
        <span style={styleProp(7)}>m</span>
        <span style={styleProp(8)}>a</span>
        <span style={styleProp(9)}>r</span>
      </div>
      <div className="flip-letter title cherry">
        <span style={styleProp(10)}>A</span>
        <span style={styleProp(11)}>s</span>
        <span style={styleProp(12)}>i</span>
        <span style={styleProp(13)}>s</span>
        <span style={styleProp(14)}>t</span>
        <span style={styleProp(15)}>e</span>
        <span style={styleProp(16)}>n</span>
        <span style={styleProp(17)}>c</span>
        <span style={styleProp(18)}>i</span>
        <span style={styleProp(19)}>a</span>
      </div>

      <p className="mt-2">Recuerda avisarnos si podrás asistir y si llevarás acompañantes.</p>

      <div className='container'>
        <div className={styles['confirm-btns']}>
          <a data-aos="zoom-in-right" rel="noreferrer" href="https://api.whatsapp.com/send/?phone=5512345678&amp;text=Hola! Confirmo mi asistencia para el cumpleaños de ... 🥳" target="_blank">
            <button className="btn-pink fill w-100">
              <img src={WhatsAppIcon} alt="whatsapp" />
              CONFIRMAR A MAMA
            </button>
          </a>
          <a data-aos="zoom-down-right" rel="noreferrer" href="https://api.whatsapp.com/send/?phone=5523180781&amp;text=Hola! Confirmo mi asistencia para el cumpleaños de ... 🥳" target="_blank">
            <button className="btn-pink fill w-100">
              <img src={WhatsAppIcon} alt="whatsapp" />
              CONFIRMAR A PAPA
            </button>
          </a>
        </div>
      </div>

      <div className={styles['container-img']}>
        <p>¡Te esperamos para celebrar juntos!</p>

        <div className="jump-letter">
          <img style={styleProp(1)} src={SullyPartyImg} alt="Sully Party" />
          <img style={styleProp(2)} src={BooPartyImg} alt="Boo Party" />
          <img style={styleProp(3)} src={MikePartyImg} alt="Mike Party" />
        </div>
      </div>
    </section>
  );
};

export default Name
