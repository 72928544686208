import React from 'react';
import MetaImg from '../../assets/cover/intro.png'
import styles from './Video.module.scss'

const Video = () => {
  return (
    <section id="video" className={styles['video-section']} data-aos="fade-down">
        <video controls loop muted data-aos="flip-down" poster={MetaImg}>
          <p>Lo sentimos. Este video no puede reproducirse en este navegador.</p>
        </video>
      </section>
  );
};

export default Video