import React from 'react';
import { Bars } from 'react-loader-spinner';
import styles from './Loader.module.scss';

const Loader = () => (
  <div className={styles['loader']} data-aos="zoom-in">
    <Bars
      height="80"
      width="80"
      color="#CB6CE6"
      ariaLabel="Cargando invitación..."
      visible={true}
    />
    CARGANDO INVITACION...
  </div>
);

export default Loader;
