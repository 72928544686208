import React, { useEffect, useState } from 'react';
import './App.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import party from "party-js";
import Address from './components/Address/Address';
import Confirm from './components/Confirm/Confirm';
import Countdown from './components/Countdown/Countdown';
import Footer from './utils/Footer/Footer';
import Description from './components/Description/Description';
import Dresscode from './components/Dresscode/Dresscode';
import Family from './components/Family/Family';
import Home from './components/Home/Home';
import Intro from './components/Intro/Intro';
import Loader from './utils/Loader/Loader'
import Player from './utils/Player/Player';
import Presents from './components/Presents/Presents';
import Sidebar from './utils/Sidebar/Sidebar';
import Video from './components/Video/Video';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isIntro, setIsIntro] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  useEffect(() => {
    AOS.init();
  }, [])

  const setExplodingConfetti = () => {
    party.confetti(document.body, {
      count: party.variation.range(500, 1000),
    });
  };

  const handleIntro = () => {
    setIsIntro(false)
  }

  return (
    <div className={`main ${!isIntro ? 'with-bg' : ''}`} onClick={setExplodingConfetti} onContextMenu={(e)=> e.preventDefault()}>
      {isLoading ?
        <Loader />
      :
        <>
          {isIntro ?
            <>
              <Intro handleIntro={handleIntro} />
              <Footer intro={isIntro} />
            </>
          :
            <>
              <Player />
              <Sidebar />
              <Home />
              <Countdown />
              <Description />
              <Video />
              <Address />
              <Dresscode />
              <Family />
              <Presents />
              <Confirm />
              <Footer />
            </>
          }
        </>
      }
    </div>
  );
}

export default App;
