import React from 'react';
import styles from './Description.module.scss'

const Description = () => {
  return (
    <section id="description" className={styles['description-section']} data-aos="fade-right">
      <div className="container">
        <h2 className="title cherry mb-3">¡Sustos que dan gusto!</h2>
        <p>Respice ... Sentio sicut ego vobis exponam: sed vias breve iterum conabor. Fugere hoc maior difficultas est nobis. Perdet eam batch nostri. Et delebis eam ac omnia opus est vestigium, ut possimus coques. Sin id, quod morte morieris. Locus non est peccatum. Nec apud hos. 🎉</p>
      </div>
    </section>
  );
};

export default Description