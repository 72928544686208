import React from 'react';
import MonstersIncImg from '../../assets/img/1.png'
import styles from './Home.module.scss'

const Home = () => {
  const styleProp = (index: number) => {
    return { "--i": index } as React.CSSProperties
  }

  return (
    <section className={styles['home-section']} id="home">
      <img src={MonstersIncImg} alt="1" />

      <h1 className="cherry">Mi primer añito</h1>
      <div className="jump-letter">
        <span className="light-purple" style={styleProp(1)}>N</span>
        <span className="green" style={styleProp(2)}>O</span>
        <span className="orange"  style={styleProp(3)}>M</span>
        <span className="light-blue" style={styleProp(4)}>B</span>
        <span className="yellow" style={styleProp(5)}>R</span>
        <span className="pink" style={styleProp(6)}>E</span>
      </div>
    </section>
  );
};

export default Home
