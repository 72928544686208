import React from 'react';
import HatGreenPartyImg from '../../assets/img/hat_green_party.png'
import HatOrangePartyImg from '../../assets/img/hat_orange_party.png'
import HatPinkPartyImg from '../../assets/img/hat_pink_party.png'
import MikeMamaImg from '../../assets/img/mike_mama.png'
import SullyPapaImg from '../../assets/img/sully_papa.png'
import RandalphBroImg from '../../assets/img/randalph_bro.png'
import styles from './Family.module.scss';

const Family = () => {
  const styleProp = (index: number) => {
    return { "--i": index } as React.CSSProperties
  }

  return (
    <section id="family" className={styles['family-section']} data-aos="fade-right">
    <div className="flip-letter title cherry">
      <span style={styleProp(1)}>P</span>
      <span style={styleProp(2)}>e</span>
      <span style={styleProp(3)}>r</span>
      <span style={styleProp(4)}>s</span>
      <span style={styleProp(5)}>o</span>
      <span style={styleProp(6)}>n</span>
      <span style={styleProp(7)}>a</span>
      <span style={styleProp(8)}>s</span>
    </div>
    <div className="flip-letter title cherry">
      <span style={styleProp(9)}>F</span>
      <span style={styleProp(10)}>a</span>
      <span style={styleProp(11)}>v</span>
      <span style={styleProp(12)}>o</span>
      <span style={styleProp(13)}>r</span>
      <span style={styleProp(14)}>i</span>
      <span style={styleProp(15)}>t</span>
      <span style={styleProp(16)}>a</span>
      <span style={styleProp(17)}>s</span>
    </div>
    <p className="mt-2">Quienes hacen posible este día.</p>
    <div className={styles['persons-container']}>
      <div className="mt-2 card text-center" data-aos="flip-right">
        <img className={styles['birthday-hat']} src={HatPinkPartyImg} alt="Birthday Hat Pink" />
        <img src={MikeMamaImg} alt="Mom" />
        <p className={`${styles['name']} monster mt-2`}>
          <span className="light-purple">N</span>
          <span className="green">O</span>
          <span className="orange">M</span>
          <span className="light-blue">B</span>
          <span className="yellow">R</span>
          <span className="pink">E</span>
        </p>
        <p>Mamá</p>
      </div>
      <div className="mt-2 card text-center" data-aos="flip-right">
        <img className={styles['birthday-hat']} src={HatGreenPartyImg} alt="Birthday Hat Green" />
        <img src={SullyPapaImg} alt="Dad" />
        <p className={`${styles['name']} monster mt-2`}>
          <span className="light-purple">N</span>
          <span className="green">O</span>
          <span className="orange">M</span>
          <span className="light-blue">B</span>
          <span className="yellow">R</span>
          <span className="pink">E</span>
        </p>
        <p>Papá</p>
      </div>
      <div className="mt-2 card text-center" data-aos="flip-right">
        <img className={styles['birthday-hat']} src={HatOrangePartyImg} alt="Birthday Hat Yellow" />
        <img src={RandalphBroImg} alt="Bro" />
        <p className={`${styles['name']} monster mt-2`}>
          <span className="light-purple">N</span>
          <span className="green">O</span>
          <span className="orange">M</span>
          <span className="light-blue">B</span>
          <span className="yellow">R</span>
          <span className="pink">E</span>
        </p>
        <p>Hermanx</p>
      </div>
    </div>
  </section>
  );
};

export default Family;
